import React from "react";
import styled from "styled-components"
import BlogThumbnail from './blogThumbnail';

const RootContainer = styled.div`
    display: flex;
    flex-wrap:wrap;    
    justify-content:space-around;
`;

const BlogRoll = ({data}) => {

  const posts = data.data.allMarkdownRemark.edges
  console.log(posts);

    const blogThumbnails = posts.map(({ node }) => {  
      const title = node.frontmatter.title || node.fields.slug

      return (
        <BlogThumbnail 
        key={node.fields.slug} 
        heading={title} 
        description={node.frontmatter.description} 
        slug={node.fields.slug}
        featureImage={node.frontmatter.featuredimage}
        />      
      )    
  });

  return (
    <RootContainer>
        {blogThumbnails}        
    </RootContainer>
    )
}

export default BlogRoll;