import React from "react";
import { graphql } from 'gatsby' 
import Layout from "../components/Layout/layout"
import BlogRoll from "../components/Blog/blogRoll";

const BlogPage = (data) => (    
        <Layout>
            <BlogRoll data={data}/>
        </Layout>    
);

export default BlogPage;

export const pageQuery = graphql`
  query {    
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
             
          }
        }
      }
    }
  }
`