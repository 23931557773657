import React from "react";
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image";
import { Text} from '../Layout/pageParts';

const Container = styled.div`   
    width: 400px;
    min-height:200px;
    margin: 50px;
`;

const CardDetails = styled.div`
    padding:2%;
`;

const ReadMoreButton = styled(props => <Link {...props} />)` 
    font-weight: 400;
    font-size:18px;   
    background:transparent;
    color: #515151;
    transition: color .2s ease-in;  
    border-bottom: 1px solid #B7B8B6;   
    align-self:center;
`;

const BlogThumbnail = ({heading, description, slug, featureImage}) => {
    //style={{ width: "100%", height: "50%" }}

    return ( 
        <Container>     
            {featureImage && <Link  to={slug}><Img fluid={featureImage.childImageSharp.fluid} /> </Link>    }  
            <CardDetails>
                <Link to={slug}>{heading}</Link>
                <Text>{description}</Text>
                <ReadMoreButton to={slug}>READ MORE</ReadMoreButton>
            </CardDetails>            
        </Container>
    )
}

export default BlogThumbnail;